@import "../../styles/styles.scss";

.token-select {
  z-index: 2;

  ::placeholder {
    color: $ui-border;
  }

  :-ms-input-placeholder {
    color: $ui-border;
  }

  ::-ms-input-placeholder {
    color: $ui-border;
  }

  .arrow {
    transition: 0.1s all ease-in-out;
  }
}

.token-selected {
  background-color: $ui-main-background;
  cursor: pointer;
  border: 1px solid $ui-border;
  border-radius: 25px;
  color: $ui-main;
  padding: 11px 18px;

  .clear-icon {
    transition: all 200ms;

    &:hover {
      transform: scale(1.25);
    }
  }
}

.token-select-dropdown-list {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 20px;
  width: 100%;
  min-width: 80px;
  max-height: 260px;
  overflow-y: scroll;
  list-style: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 25px;
  box-shadow: var(--selectedWindowShadow);
  background-color: $ui-main-background;
  transition: all 300ms;

  &-show {
    visibility: visible;
    opacity: 1;
  }

  &-fade {
    visibility: hidden;
    opacity: 0;
  }

  li {
    position: relative;
    color: $ui-main;
    line-height: 24px;
    padding: 16px 16px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    transition: all 200ms;

    &.token-select-item:hover {
      background-color: $ui-web-background;
    }
  }

  &:hover {
    color: $brand-primary;
  }
}

.error-wrapped-token {
  font-size: 12px;
  color: $attention-alarm;
  position: absolute;
  font-size: 12px;
  line-height: 12px;
  left: 1px;
  bottom: 0px;
}
